import { useState, useEffect, useContext } from "react";
import { ApiUrl } from "../../API/Api.config";
import axios from "axios";
import TokenService from "../../API/token.service";
import { Link, useNavigate } from "react-router-dom";
import FacebookLogin from '@greatsumini/react-facebook-login';
import './appsocial.style.scss';

const AppSocial = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [instaAccessToken, setInstaAccessToken] = useState(null);
    const [instagramAccountID, setInstagramAccountID] = useState(null);
    const [impressions, setImpressions] = useState(null);
    const currentUrl = window.location.pathname;
    const navigate = useNavigate();
    const [visibleComponent, setVisibleComponent] = useState(null);


    const ENVIRONMENT = 0

    if (ENVIRONMENT === 1) {
        console.log = () => { }
        console.error = () => { }
        console.debug = () => { }
    }

    // Rizvi's Code Start

    // const handleFacebookResponse = (response) => {
    //     // console.log('Facebook login response:', response);
    //     if (response.accessToken) {
    //         setAccessToken(response.accessToken);
    //         getInstagramAccount(response.accessToken);
    //     }
    // };

    // const getInstagramAccount = (token) => {
    //     const url = `https://graph.facebook.com/me/accounts?access_token=${token}`;
    //     axios.get(url)
    //         .then((res) => {
    //             // console.log(res.data.data[0].access_token)
    //             getInstagramStuff(res.data.data[0].access_token);
    //         })
    //         .catch((err) => console.error(err));
    // };


    // const getInstagramAccount = (token) => {
    //     const url = `https://graph.facebook.com/me/accounts?access_token=${token}`;
    //     axios.get(url)
    //       .then((res) => {
    //         const pageID = res.data.data[0].id;
    //         console.log(res.data.data[0].access_token)
    //         setInstaAccessToken(res.data.data[0].access_token)
    //         getInstagramID(pageID, token);
    //       })
    //       .catch((err) => console.error(err));
    // };

    // const getInstagramID = (pageID, token) => {
    //     const url = `https://graph.facebook.com/${pageID}?fields=instagram_business_account&access_token=${token}`;
    //     axios.get(url)
    //       .then((res) => {
    //         const instagramID = res.data.instagram_business_account.id;
    //         setInstagramAccountID(instagramID);
    //         getInstagramStuff(token);
    //       })
    //       .catch((err) => console.error(err));
    // };

    // async function getInstagramStuff(token) {
    //     try {
    //         await axios.post(ApiUrl + 'mobile/instagram_followers/', {
    //             token: instaAccessToken
    //         },
    //             {
    //                 headers: {
    //                     "Content-type": "application/json;",
    //                 }
    //             }).then(function (response) {
    //                 console.log(response)
    //             })
    //     } catch {

    //     }

    // }

    // Rizvi's Code End


    const handleClick = (component) => {
        setVisibleComponent(component);
    };

    const handleFacebookResponse = (response) => {
        console.log('Facebook login response:', response);
        if (response.accessToken) {
            setAccessToken(response.accessToken);
            getInstagramAccount(response.accessToken);
        }
    };

    const getInstagramAccount = (token) => {
        const url = `https://graph.facebook.com/me/accounts?access_token=${token}`;
        axios.get(url)
            .then((res) => {
                console.log(res)
                const pageID = res.data.data[0].id;
                getInstagramID(pageID, token);
            })
            .catch((err) => console.error(err));
    };

    const getInstagramID = (pageID, token) => {
        const url = `https://graph.facebook.com/${pageID}?fields=instagram_business_account&access_token=${token}`;
        axios.get(url)
            .then((res) => {
                const instagramID = res.data.instagram_business_account.id;
                setInstagramAccountID(instagramID);
                getImpressions(instagramID, token);
            })
            .catch((err) => console.error(err));
    };

    const getImpressions = (instagramID, token) => {
        const url = `https://graph.facebook.com/${instagramID}/insights?metric=impressions&period=week&access_token=${token}`;
        axios.get(url)
            .then((res) => {
                console.log(res.data.data)
                const impressionsData = res.data.data[0].values;
                console.log(impressionsData)
                setImpressions(impressionsData);
                navigate(`${currentUrl}?impressions=${impressionsData[0].value + impressionsData[1].value}`);
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            <div className="w-full h-full mt-10 flex justify-center items-center">
                <div className="p-10 bg-white flex flex-col justify-center items-center">
                    <div className="instagram-text">
                        <h3 className="text-2xl text-primary font-bold text-center">
                            Do you have an Instagram Creator or Business account linked to a Facebook page?
                        </h3>
                        <p className="text-center mt-3">
                            To be a Creator, you need an Instagram Creator or Business account that is linked to a Facebook page.
                        </p>
                        <div className="grid grid-cols-2 gap-5 mt-5">
                            <button className="bg-primary rounded-lg text-white p-3" onClick={() => handleClick('facebook-button')}>
                                Yes
                            </button>
                            <button className="bg-secondary rounded-lg  text-white p-3" onClick={() => handleClick('creator-steps')}>
                                No
                            </button>
                        </div>
                        {visibleComponent === 'creator-steps' && (
                            <div className="creator-steps mt-10">
                                <div className="creator-step-1 flex flex-col justidy-center items-center">
                                    <h3 className="text-xl font-bold text-center text-primary">
                                        Step 1
                                    </h3>
                                    <p className="text-center mt-3">
                                        Make sure you have switched your instagram to a Business or Creator Account
                                    </p>
                                    <button className="mt-3 bg-secondary text-center p-4 rounded-lg">
                                        <a href="https://help.instagram.com/502981923235522" className="text-white text-sm" target="_blank">
                                            Follow this
                                        </a>
                                    </button>
                                </div>
                                <div className="creator-step-2 flex flex-col justidy-center items-center mt-5">
                                    <h3 className="text-xl font-bold text-center text-primary">
                                        Step 2
                                    </h3>
                                    <p className="text-center mt-3">
                                        Make sure you have a Facebook Page and connected it to your Instagram Business or Creator account.
                                    </p>
                                    <button className="mt-3 bg-secondary text-center p-4 rounded-lg">
                                        <a href="https://help.instagram.com/570895513091465" className="text-white text-sm" target="_blank">
                                            Follow this
                                        </a>
                                    </button>
                                </div>
                            </div>
                        )}
                        {/* <div className="creator-steps mt-5">
                        </div> */}
                    </div>

                    {visibleComponent === 'facebook-button' && (
                        <div className="facebook-button mt-10 flex flex-col justify-center items-center">
                            <p className="text-center font-bold text-primary">
                                Continue with your Instagram Creator or Instagram Business Account
                            </p>
                            <div className="mt-5">
                                {!accessToken && (
                                    <FacebookLogin
                                        appId="851990559613106"
                                        onSuccess={handleFacebookResponse}
                                        onFail={(error) => console.error('Login failed:', error)}
                                        onProfileSuccess={(profile) => console.log('Profile:', profile)}
                                        fields="name,email,picture"
                                        scope="public_profile,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,read_insights"
                                        style={{
                                            backgroundColor: '#4267b2',
                                            color: '#fff',
                                            fontSize: '16px',
                                            padding: '12px 24px',
                                            border: 'none',
                                            borderRadius: '4px',
                                        }}
                                    />
                                )}
                                {impressions && (
                                    <div>
                                        <h2>Impressions for the last week</h2>
                                        <ul>
                                            {impressions.map((data, index) => (
                                                <li key={index}>
                                                    {/* {index == 0 ? 'Impressions last week: ' : 'Impressions current week: '} */}
                                                    {data.value}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default AppSocial;
