import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../API/Api.config';
import { useForm } from 'react-hook-form';
import secureLocalStorage from "react-secure-storage";
import TokenService from '../../API/token.service';
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import './Privacy.style.scss';

export default function Payment() {
    document.title = "Privacy Policy | Promote"

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    // const { setValue, handleSubmit, formState: { error } } = useForm();

    const nav = useNavigate()
    const token = TokenService.getLocalAccessToken()
    const [sessionID, setSessionID] = useState("");
    const [data, setData] = useState([]);
    const [freePackage, setFreePackage] = useState([]);
    const [pay, setPay] = useState(undefined)
    const [plan, setPlan] = useState(null);
    const date = new Date();
    const getDay = date.getDate();
    const getMonth = date.getMonth() + 1;
    const getYear = date.getFullYear();
    const fullDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (getDay <= 9 ? '0' + getDay : getDay);



    return (
        <>
            <Header />
            <div className='container'>
                <p
                    className="s1"
                    style={{
                        paddingTop: "3pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        marginTop:"100px"
                    }}
                >
                    Privacy Policy
                </p>
                <p
                    className="s2"
                    style={{
                        paddingTop: "15pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Introduction
                </p>
                <p
                    style={{
                        paddingTop: "15pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Welcome to the REACH.IO LTD privacy policy.
                </p>
                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                    REACH.IO LTD respects your privacy and is committed to protecting your
                    personal data. This privacy policy will inform you as to how we look after
                    your personal data when you visit our website (regardless of where you visit
                    it from) and tell you about your privacy
                </p>
                <p
                    style={{
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left"
                    }}
                >
                    rights and how the law protects you.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                    This privacy policy is provided in a layered format so you can click through
                    to the specific areas set out below.
                </p>
                <ol id="l1">
                    <li data-list-text={1}>
                        <p
                            style={{ paddingLeft: "41pt", textIndent: "-11pt", textAlign: "left" }}
                        >
                            [IMPORTANT INFORMATION AND WHO WE ARE]
                        </p>
                    </li>
                    <li data-list-text={2}>
                        <p
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-11pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            [THE DATA WE COLLECT ABOUT YOU]
                        </p>
                    </li>
                    <li data-list-text={3}>
                        <p
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-11pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            [HOW IS YOUR PERSONAL DATA COLLECTED?]
                        </p>
                    </li>
                    <li data-list-text={4}>
                        <p
                            style={{ paddingLeft: "41pt", textIndent: "-11pt", textAlign: "left" }}
                        >
                            [HOW WE USE YOUR PERSONAL DATA]
                        </p>
                    </li>
                    <li data-list-text={5}>
                        <p
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-11pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            [DISCLOSURES OF YOUR PERSONAL DATA]
                        </p>
                    </li>
                    <li data-list-text={6}>
                        <p
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-11pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            [INTERNATIONAL TRANSFERS]
                        </p>
                    </li>
                    <li data-list-text={7}>
                        <p
                            style={{ paddingLeft: "41pt", textIndent: "-11pt", textAlign: "left" }}
                        >
                            [DATA SECURITY]
                        </p>
                    </li>
                    <li data-list-text={8}>
                        <p
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-11pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            [DATA RETENTION]
                        </p>
                    </li>
                    <li data-list-text={9}>
                        <p
                            style={{
                                paddingLeft: "41pt",
                                textIndent: "-11pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            [YOUR LEGAL RIGHTS]
                        </p>
                    </li>
                </ol>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <ol id="l2">
                    <li data-list-text={1}>
                        <p
                            className="s2"
                            style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "left" }}
                        >
                            Important information and who we are
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Purpose of this privacy policy
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            This privacy policy aims to give you information on how REACH.IO LTD
                            collects and processes your personal data through your use of this
                            website, including any data you may provide through this website when
                            you use a product or service, take part in a competition, access our
                            platform, or engage with us in any other way.
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            This website is not intended for children and we do not knowingly
                            collect data relating to children.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            It is important that you read this privacy policy together with any
                            other privacy policy or fair processing policy we may provide on
                            specific occasions when we are collecting or processing personal data
                            about you so that you are fully aware of how and why we are using your
                            data. This privacy policy supplements other notices and privacy policies
                            and is not intended to override them.
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Controller
                        </p>
                        <p
                            style={{
                                paddingTop: "3pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                            }}
                        >
                            If you have any questions about this privacy policy, including any
                            requests to exercise your legal rights, please contact PROMOTE using the
                            details set out below.
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                            }}
                        >
                            Contact details
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            If you have any questions about this privacy policy or our privacy
                            practices, please contact PROMOTE in the following ways:
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "left"
                            }}
                        >
                            <a href="mailto:admin@support.io" className="s3" target="_blank">
                                Full name of legal entity: REACH.IO Ltd Email address:{" "}
                            </a>
                            <a href="mailto:admin@support.io" target="_blank">
                                admin@support.io
                            </a>
                        </p>
                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "199%",
                                textAlign: "left"
                            }}
                        >
                            Postal address:13 Queens Road, Bournemouth BH2 6BA Telephone number: +44
                            (0) 7565 142007
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            <a href="http://www.ico.org.uk/" className="s3" target="_blank">
                                You have the right to make a complaint at any time to the Information
                                Commissioner's Office (ICO), the UK regulator for data protection
                                issues (
                            </a>
                            <a href="http://www.ico.org.uk/" className="a" target="_blank">
                                www.ico.org.uk
                            </a>
                            ). We would, however, appreciate the chance to deal with your concerns
                            before you approach the ICO so please contact us in the first instance.
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "199%",
                                textAlign: "left"
                            }}
                        >
                            Changes to the privacy policy and your duty to inform us of changes We
                            keep our privacy policy under regular review.
                        </p>
                        <p
                            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                        >
                            It is important that the personal data we hold about you is accurate and
                            current. Please keep us informed if your personal data changes during
                            your relationship with us.
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                            }}
                        >
                            Third-party links
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            This website may include links to third-party websites, plug-ins and
                            applications. Clicking on those links or enabling those connections may
                            allow third parties to collect or share data about you. We do not
                            control these third-party websites and are not responsible for their
                            privacy statements. When you leave our website, we encourage you to read
                            the privacy policy of every website you visit.
                        </p>
                    </li>
                    <li data-list-text={2}>
                        <p
                            className="s2"
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "24pt",
                                textIndent: "-18pt",
                                textAlign: "left"
                            }}
                        >
                            The data we collect about you
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Personal data, or personal information, means any information about an
                            individual from which that person can be identified. It does not include
                            data where the identity has been removed (anonymous data).
                        </p>
                        <p
                            style={{
                                paddingTop: "3pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            We may collect, use, store and transfer different kinds of personal data
                            about you which we have grouped together as follows:
                        </p>
                        <ul id="l3">
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Identity Data includes first name, maiden name, last name, username
                                    or similar identifier, marital status, title, date of birth and
                                    gender.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Contact Data includes billing address, delivery address, email
                                    address and telephone numbers.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        lineHeight: "15pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Financial Data includes bank account and payment card details.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Transaction Data includes details about payments to and from you and
                                    other details of products and services you have purchased from us.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Technical Data includes internet protocol (IP) address, your login
                                    data, browser type and version, time zone setting and location,
                                    browser plug-in types and versions, operating system and platform,
                                    and other technology on the devices you use to access this website.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Profile Data includes your username and password, your interests,
                                    preferences, feedback and survey responses.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Usage Data includes information about how you use our website,
                                    products and services.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Marketing and Communications Data includes your preferences in
                                    receiving marketing from us and our third parties and your
                                    communication preferences.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                >
                                    We also collect, use and share Aggregated Data such as statistical
                                    or demographic data for any purpose. Aggregated Data could be
                                    derived from your personal data but is not considered personal data
                                    in law as this data will not directly or indirectly reveal your
                                    identity. For example, we may aggregate your Usage Data to calculate
                                    the percentage of users accessing a specific website feature.
                                    However, if we combine or connect Aggregated Data with your personal
                                    data so that it can directly or indirectly identify you, we treat
                                    the combined data as personal data which will be used in accordance
                                    with this privacy policy.
                                </p>
                                <p
                                    style={{
                                        paddingTop: "15pt",
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    We do not collect any Special Categories of Personal Data about you
                                    (this includes details about your race or ethnicity, religious or
                                    philosophical beliefs, sex life, sexual orientation, political
                                    opinions, trade union membership, information about your health, and
                                    genetic and biometric data). Nor do we collect any information about
                                    criminal convictions and offences.
                                </p>
                                <p
                                    style={{
                                        paddingTop: "15pt",
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    If you fail to provide personal data
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                >
                                    Where we need to collect personal data by law, or under the terms of
                                    a contract we have with you, and you fail to provide that data when
                                    requested, we may not be able to perform the contract we have or are
                                    trying to enter into with you (for example, to provide you with
                                    goods or services). In this case, we may have to cancel a product or
                                    service you have with us but we will notify you if this is the case
                                    at the time.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text={3}>
                        <p
                            className="s2"
                            style={{
                                paddingTop: "7pt",
                                paddingLeft: "24pt",
                                textIndent: "-18pt",
                                textAlign: "left"
                            }}
                        >
                            How is your personal data collected?
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            We use different methods to collect data from and about you including
                            through:
                        </p>
                        <ul id="l4">
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Direct interactions. You may give us your data by filling in forms
                                    or by corresponding with us by post, phone, email or otherwise. This
                                    includes personal data you provide when you apply for our products
                                    or services;
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        lineHeight: "16pt",
                                        textAlign: "left"
                                    }}
                                >
                                    create an account on our website;
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        textAlign: "left"
                                    }}
                                >
                                    use our services
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        textAlign: "left"
                                    }}
                                >
                                    give us feedback or contact us.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Automated technologies or interactions. As you interact with our
                                    website, we will automatically collect Technical Data about your
                                    equipment, browsing actions and patterns. We collect this personal
                                    data by using cookies and other similar technologies
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text={4}>
                        <p
                            className="s2"
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "24pt",
                                textIndent: "-18pt",
                                textAlign: "left"
                            }}
                        >
                            How we use your personal data
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            We will only use your personal data for the purposes for which it has
                            been provided to us. Generally, we will process your personal data in
                            order to provide you with those of our services and other offerings you
                            want to receive from us, but we occasionally do market research.
                        </p>
                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            Purposes for which we will use your personal data
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            We have set out below, in a table format, a description of all the ways
                            we plan to use your personal data, and which of the legal bases we rely
                            on to do so. We have also identified what our legitimate interests are
                            where appropriate.
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Note that we may process your personal data for more than one lawful
                            ground depending on the specific purpose for which we are using your
                            data. Please contact us if you need details about the specific legal
                            ground we are relying on to process your personal data where more than
                            one ground has been set out in the table below.
                        </p>
                        <p style={{ paddingTop: "15pt", textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Purpose/Activity Type of data
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Lawful basis for processing including basis of legitimate interest To
                            register you as a new Influencer
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Identity Contact
                        </p>
                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "15pt",
                                textAlign: "left"
                            }}
                        >
                            Performance of a contract with you
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            To process your campaign including to: Manage payments, fees and charges
                            Collect and recover money owed to us
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Identity Contact Financial Transaction Marketing Communications
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Performance of a contract with you Necessary for our legitimate
                            interests (to recover debts due to us)
                        </p>
                        <p
                            style={{
                                paddingTop: "3pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                            }}
                        >
                            To manage our relationship with you which will include: Notifying you
                            about changes to our terms or privacy policy Asking you to leave a
                            review or take a survey
                        </p>
                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "16pt",
                                textAlign: "justify"
                            }}
                        >
                            Identity Contact Profile Marketing Communications
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                            }}
                        >
                            Performance of a contract with you Necessary to comply with a legal
                            obligation Necessary for our legitimate interests (to keep our records
                            updated and to study how customers use our products/services)
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            To administer and protect our business and this website (including
                            troubleshooting, data analysis, testing, system maintenance, support,
                            reporting and hosting of data) Identity Contact Technical
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Necessary for our legitimate interests (for running our business,
                            provision of administration and IT services, network security, to
                            prevent fraud and in the context of a business reorganisation or group
                            restructuring exercise) Necessary to comply with a legal obligation
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            To deliver relevant website content and advertisements to you and
                            measure or understand the effectiveness of the advertising we serve to
                            you
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Identity Contact Profile Usage Marketing Communications Technical
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Necessary for our legitimate interests (to study how customers use our
                            products/services, to develop them, to grow our business and to inform
                            our marketing strategy)
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            To use data analytics to improve our website, products/services,
                            marketing, customer relationships and experiences
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Technical Usage
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Necessary for our legitimate interests (to define types of customers for
                            our products and services, to keep our website updated and relevant, to
                            develop our business and to inform our marketing strategy)
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            To make suggestions and recommendations to you about goods or services
                            that may be of interest to you
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Identity Contact Technical Usage Profile Marketing Communications
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Necessary for our legitimate interests (to develop our products/services
                            and grow our business)
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Cookies
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            You can set your browser to refuse all or some browser cookies, or to
                            alert you when websites set or access cookies. If you disable or refuse
                            cookies, please note that some parts of this website may become
                            inaccessible or not function properly.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Change of purpose
                        </p>
                        <p
                            style={{
                                paddingTop: "3pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            We will only use your personal data for the purposes for which we
                            collected it, unless we reasonably consider that we need to use it for
                            another reason and that reason is compatible with the original purpose.
                            If you wish to get an explanation as to how the processing for the new
                            purpose is compatible with the original purpose, please contact us.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            If we need to use your personal data for an unrelated purpose, we will
                            notify you and we will explain the legal basis which allows us to do so.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            Please note that we may process your personal data without your
                            knowledge or consent, in compliance with the above rules, where this is
                            required or permitted by law.
                        </p>
                        <p style={{ paddingTop: "15pt", textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                    </li>
                    <li data-list-text={5}>
                        <p
                            className="s2"
                            style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "left" }}
                        >
                            Disclosures of your personal data
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            We may share your personal data with the parties set out below for the
                            purposes set out in the table above.
                        </p>
                        <ul id="l5">
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Third parties to whom we may choose to sell, transfer or merge parts
                                    of our business or our assets. Alternatively, we may seek to acquire
                                    other businesses or merge with them. If a change happens to our
                                    business, then the new owners may use your personal data in the same
                                    way as set out in this privacy policy.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "47pt",
                                        textIndent: "-18pt",
                                        textAlign: "left"
                                    }}
                                >
                                    We require all third parties to respect the security of your
                                    personal data and to treat it in accordance with the law. We do not
                                    allow our third-party service providers to use your personal data
                                    for their own purposes and only permit them to process your personal
                                    data for specified purposes and in accordance with our instructions.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text={6}>
                        <p
                            className="s2"
                            style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "left" }}
                        >
                            Data security
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            We have put in place appropriate security measures to prevent your
                            personal data from being accidentally lost, used or accessed in an
                            unauthorised way, altered or disclosed.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            In addition, we limit access to your personal data to those employees,
                            agents, contractors and other third parties who have a business need to
                            know. They will only process your personal data on our instructions and
                            they are subject to a duty of confidentiality.
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                            }}
                        >
                            We have put in place procedures to deal with any suspected personal data
                            breach and will notify you and any applicable regulator of a breach
                            where we are legally required to do so.
                        </p>
                    </li>
                    <li data-list-text={7}>
                        <p
                            className="s2"
                            style={{
                                paddingTop: "2pt",
                                paddingLeft: "24pt",
                                textIndent: "-18pt",
                                textAlign: "left"
                            }}
                        >
                            Data retention
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            How long will you use your personal data for?
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            We will only retain your personal data for as long as reasonably
                            necessary to fulfil the purposes we collected it for, including for the
                            purposes of satisfying any legal, regulatory, tax, accounting or
                            reporting requirements. We may retain your personal data for a longer
                            period in the event of a complaint or if we reasonably believe there is
                            a prospect of litigation in respect to our relationship with you.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                            To determine the appropriate retention period for personal data, we
                            consider the amount, nature and sensitivity of the personal data, the
                            potential risk of harm from unauthorised use or disclosure of your
                            personal data, the purposes for which we process your personal data and
                            whether we can achieve those purposes through other means, and the
                            applicable legal, regulatory, tax, accounting or other requirements.
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            In some circumstances you can ask us to delete your data: see your legal
                            rights below for further information.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                    </li>
                    <li data-list-text={8}>
                        <p
                            className="s2"
                            style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "left" }}
                        >
                            Your legal rights
                        </p>
                        <p
                            style={{
                                paddingTop: "15pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left"
                            }}
                        >
                            Under certain circumstances, you have rights under data protection laws
                            in relation to your personal data.
                        </p>
                        <ul id="l6">
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingTop: "15pt",
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Request access to your personal data.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        lineHeight: "15pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Request correction of your personal data.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        lineHeight: "15pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Request erasure of your personal data.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Object to processing of your personal data.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Request restriction of processing your personal data.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        lineHeight: "15pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Request transfer of your personal data.
                                </p>
                            </li>
                            <li data-list-text="">
                                <p
                                    style={{
                                        paddingLeft: "41pt",
                                        textIndent: "-11pt",
                                        lineHeight: "15pt",
                                        textAlign: "left"
                                    }}
                                >
                                    Right to withdraw consent.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ol>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                    <a href="mailto:admin@re.io" className="s3" target="_blank">
                        If you wish to exercise any of the rights set out above, please contact us
                        at{" "}
                    </a>
                    <a href="mailto:admin@re.io" target="_blank">
                        admin@re.io
                    </a>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                    No fee usually required
                </p>
                <p
                    style={{
                        paddingTop: "15pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    You will not have to pay a fee to access your personal data (or to exercise
                    any of the other rights). However, we may charge a reasonable fee if your
                    request is clearly unfounded, repetitive or excessive. Alternatively, we
                    could refuse to comply with your request in these circumstances.
                </p>
                <p
                    style={{
                        paddingTop: "3pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    What we may need from you
                </p>
                <p
                    style={{
                        paddingTop: "15pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    We may need to request specific information from you to help us confirm your
                    identity and ensure your right to access your personal data (or to exercise
                    any of your other rights). This is a security measure to ensure that
                    personal data is not disclosed to any person who has no right to receive it.
                    We may also contact you to ask you for further information in relation to
                    your request to speed up our response.
                </p>
                <p
                    style={{
                        paddingTop: "15pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Time limit to respond
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                    We try to respond to all legitimate requests within one month. Occasionally
                    it could take us longer than a month if your request is particularly complex
                    or you have made a number of requests. In this case, we will notify you and
                    keep you updated.
                </p>
                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                    We may need to request specific information from you to help us confirm your
                    identity and ensure your right to access your personal data (or to exercise
                    any of your other rights). This is a security measure to ensure that
                    personal data is not disclosed to any person who has no right to receive it.
                    We may also contact you to ask you for further information in relation to
                    your request to speed up our response.
                </p>

            </div>
        </>

    )
}
