import React, { useState, useEffect } from 'react';
import TokenService from '../../API/token.service';
import useAxios, { imgApi } from '../../API/Api.config';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../API/axiosConfig';
import { userContext } from "../../components/Context/UserContext";
import { useLocation } from 'react-router-dom';
import './casestudy.style.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './casestudy.style.scss';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DonutChart from '../../components/DonutChart/DonutChart';




const CaseStudyById = () => {


    const data = useLocation()

    useEffect(() => {
        // Scroll to the top of the page when this component is rendered
        window.scrollTo(0, 0);
    }, [data]);

    return (
        <>
            <Header></Header>
            <div id='EachCaseStudy'>
                <div className='container mx-auto mt-36'>
                    <div className="casestudy-start-image mt-10 rounded-promote flex flex-col justify-center items-center" style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + data.state.image + ")", backgroundPosition: "center " + data.state.bgPosition }}>
                        <div className='logo-container bg-white rounded-promote flex justify-center items-center'>
                            <img src={data.state.logo} alt="Promote Case Study 1" className='case-study-logo' />
                        </div>
                    </div>
                    <div className='cs-overview'>
                        <div className='grid grid-cols-5 sm:gap-y-0 gap-y-8'>
                            <div className='sm:col-span-3 col-span-5'>
                                <p className='font-bold'>
                                    Overview
                                </p>
                                <p className='mt-5 overview-text' dangerouslySetInnerHTML={{ __html: data.state.overview }}></p>

                            </div>
                            <div className='sm:col-span-1 col-span-5 sm:col-start-5 col-start-1'>
                                <div>
                                    <p className='sm:text-end text-start font-bold'>
                                        Client
                                    </p>
                                    <p className='sm:text-end text-start'>
                                        {data.state.client}
                                    </p>
                                </div>
                                <div className='mt-10'>
                                    <p className='sm:text-end text-start font-bold'>
                                        Location
                                    </p>
                                    <p className='sm:text-end text-start'>
                                        {data.state.location}
                                    </p>
                                </div>
                                <div className='mt-10'>
                                    <p className='sm:text-end text-start font-bold'>
                                        Service
                                    </p>
                                    <p className='sm:text-end text-start'>
                                        {data.state.service}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='call-to-action-banner bg-secondary mt-10 p-5 rounded-promote'>
                        <div className='flex justify-between'>
                            <div className='flex justify-center items-center'>
                                <p className='text-white mb-0 call-to-action-text'>
                                    Ready to Make an Impact?
                                </p>
                            </div>
                            <div className='flex justify-center items-center'>
                                <button className='bg-primary p-2 rounded-promote hover:bg-secondary'>
                                    <a
                                        href="https://calendly.com/promote-io/30min"
                                        className="text-white focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 focus:outline-none focus:ring-black w-full block"
                                        aria-current="page"
                                    >
                                        <b>Book a Meeting</b> <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className='all-results'>
                        <p className='text-center font-bold'>
                            Our Work
                        </p>
                        <div className='grid grid-cols-4 gap-6'>
                            <div className={"mt-5  " + (data.state.horizontalVideo == 1 ? "col-span-4" : "sm:col-span-1 col-span-4")}>
                                <video width="100%" controls className='rounded-promote bg-gray h-full'>
                                    <source src={data.state.video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className={(data.state.horizontalVideo == 1 ? "col-span-4" : "sm:col-span-3 col-span-4")}>
                                <div className='mt-5 results rounded-promote'>
                                    <div className='grid sm:grid-cols-4 grid-cols-1 sm:grid-row-2 grid-row-6 gap-6'>

                                        <div className='sm:col-span-2 col-span-1 bg-white row-span-1 p-5 rounded-promote bg-gray'>
                                            <p className='font-bold text-center'>
                                                Total Views
                                            </p>
                                            <div>
                                                <div className="w-full bg-white">
                                                    {/* Donut Chart */}
                                                    <div className="mt-5">
                                                        {/* <Chart options={getChartOptions()} series={chartData.series} type="donut" height={275} /> */}
                                                        <DonutChart
                                                            labels={data.state.viewsLabel}
                                                            seriesData={data.state.views}
                                                            colors={data.state.viewsColor}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sm:col-span-2 col-span-1 bg-white row-span-1 p-5 rounded-promote bg-gray'>
                                            <p className='font-bold text-center'>
                                                Forms of Content
                                            </p>
                                            <div>
                                                <div className="w-full bg-white">
                                                    {/* Donut Chart */}
                                                    <div className="mt-5">
                                                        {/* <Chart options={getChartOptions()} series={chartData.series} type="donut" height={275} /> */}

                                                        {/* {data.state.contentColor + ' ' + data.state.content + ' '  + data.state.contentLabel  } */}

                                                        <DonutChart
                                                            labels={data.state.contentLabel}
                                                            seriesData={data.state.content}
                                                            colors={data.state.contentColor}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-1 bg-white row-span-1 p-5 rounded-promote bg-gray'>
                                            <p className='font-bold text-center'>
                                                Creators Attended
                                            </p>
                                            <div className='h-[85%] flex justify-center items-center flex-col'>
                                                <i class="fa-solid fa-users text-3xl text-primary"></i>
                                                <p className='text-3xl text-center mt-3'>
                                                    {data.state.creators}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-span-1 row-span-1 p-5 rounded-promote bg-gray'>
                                            <p className='font-bold text-center'>
                                                Likes
                                            </p>
                                            <p className=''>
                                            </p>
                                            <div className='h-[85%] flex justify-center items-center flex-col py-5'>
                                                <i class="fa-solid fa-heart text-3xl text-primary"></i>
                                                <p className='text-3xl text-center mt-3'>
                                                    {data.state.likes}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-span-1 bg-white row-span-1 p-5 rounded-promote bg-gray'>
                                            <p className='font-bold text-center'>
                                                Comments
                                            </p>
                                            <div className='h-[85%] flex justify-center items-center flex-col py-5'>
                                                <i class="fa-solid fa-comments text-3xl text-primary"></i>
                                                <p className='text-3xl text-center mt-3'>
                                                    {data.state.comments}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-span-1 bg-white row-span-1 p-5 rounded-promote bg-gray'>
                                            <p className='font-bold text-center'>
                                                Saves and Sends
                                            </p>
                                            <div className='h-[85%] flex justify-center items-center flex-col py-5'>
                                                <i class="fa-solid fa-share text-3xl text-primary"></i>
                                                <p className='text-3xl text-center mt-3'>
                                                    {data.state.shares}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className='sm:col-span-4 col-span-1'>
                                            <div className='flex justify-end items-end'>
                                                <button className='bg-primary p-3 rounded-promote flex justify-center items-center hover:bg-secondary'>
                                                    <a
                                                        href="https://calendly.com/promote-io/30min"
                                                        className="text-white focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 focus:outline-none focus:ring-black w-full block"
                                                        aria-current="page"
                                                    >
                                                        <b>Book a Meeting</b> <i class="fa-solid fa-arrow-right"></i>
                                                    </a>
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default CaseStudyById;